@import url('https://fonts.googleapis.com/css?family=Montserrat%3A400%2C%2C700&display=fallback&ver=3.7.9');

:root{
  --primary: #000000;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */
body{
  font-family: "Montserrat";
  color: var(--secondary);
}
.App{
  /* max-width: 1024px; */
  margin: 0 auto;
}
.title h1{
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2, .title p{
  text-align: center;
}
.title h2{
  margin-top: 60px;
  font-size: 2.6rem;
}

footer {
  /* display: block; */
  /* flex-grow: 1; */
  position: "fixed";
  width: 100%;
  left:0;
  bottom: 0;
  margin: 0;
}